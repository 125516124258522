import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Box, Card, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput, Stack, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { categoryApi } from "../../store/categoryDetails/categoryList";
import { courseAddApi } from "../../store/courses/addCourse";
import { courseDetailsApi } from "../../store/courses/courseDetails";
import { courseEditApi } from "../../store/courses/editCourse";
import AddCouresSection from "./AddCouresSection";
import ImageUploading from "react-images-uploading";
import RichTextEditor from "react-rte";
import { resetHandoutUpload, uploadHandoutsApi } from "../../store/upload/uploadHandouts";
import { uploadImageApi } from "../../store/upload/uploadImage";
import { classGlobalApi } from "../../store/class/classListGlobal";
import { subjectGlobalApi } from "../../store/subject/subjectListGlobal";
import { useTheme } from '@mui/material/styles';
import BackdropUI from "../../ui/Backdrop";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const schema = yup
  .object({
    name: yup.string().required("Please Provide Name").trim(),
    // intro_video: yup.string().required("Please Provide Video Link").trim(),
    duration: yup.string().required("Please Provide Course Duration").trim(),
    stars: yup
      .number()
      .required("Please Provide Number Of Stars ")
      .typeError("Please Provide A Number")
      .min(1, "Minimum stars should be 1")
      .max(1000, "Max Stars Can Be 1000"),
    category: yup.string().required("Please Provide A Catergory "),
    pacing_type: yup.string().required("Please Provide A Pacing type "),
    // description: yup.string().required("Please Provide A Description ").trim(),
    organization: yup
      .string()
      .required("Please Provide A organization ")
      .trim(),
    run: yup.string().required("Please Provide Run ").trim(),
    number: yup.string().required("Please Provide A Course number ").trim(),
    language: yup.string().required("Please Select Language "),

  })
  .required();
const schemaEdit = yup
  .object({
    name: yup.string().required("Please Provide Content Name").trim(),
    // intro_video: yup.string().required("Please Provide Video Link").trim(),
    stars: yup
      .number()
      .required("Please Provide Number Of Stars ")
      .typeError("Please provide a number")
      .min(1, "Minimum stars should be 1")
      .max(1000, "Max Stars Can Be 1000"),
    duration: yup.string().required("Please Provide Course Duration"),
    category: yup.string().required("Please Provide A Catergory "),
    language: yup.string().required("Please Select Language "),
    pacing_type: yup.string().required("Please Provide A Pacing type "),
    // description: yup.string().required("Please Provide A Description ").trim(),

  })
  .required();
export default function AddCourse() {
  const theme = useTheme();
  const { result: classList, loading: classLoading } = useSelector((state) => state?.classGlobal);
  const { result: subjectList, loading: subjectLoading } = useSelector((state) => state?.subjectGlobal);
  const coursesDetails = useSelector((state) => state?.coursesDetails);
  const { result, loading } = coursesDetails;
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const params = useParams();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(params.id ? schemaEdit : schema),
    defaultValues: {},
  });

  // const [is_free, setFree] = useState();
  const [meta, setMeta] = useState("");
  const [metaTag, setMetaTag] = useState([]);
  const [images, setImages] = React.useState([]);
  const [duration, setDuration] = useState('0:00');
  const [imageLink, setImageLink] = useState(null);
  // const [signature, setSignature] = useState([]);


  const [dateTime, setDateTime] = useState();
  const [enrollmentStart, setEnrollmentStart] = useState();
  const [enrollmentEnd, setEnrollmentEnd] = useState();
  const [cat, setCat] = useState();
  const [pacing, setPacing] = useState();
  const [endTime, setEnd] = useState();
  const ref = useRef(null);
  const navigate = useNavigate();
  const [language, setLanguage] = useState();
  const uploadHandouts = useSelector((state) => state?.uploadHandouts);
  const [handOuts, setHandouts] = React.useState([]);
  const coursesAdd = useSelector((state) => state?.coursesAdd);
  const coursesEdit = useSelector((state) => state?.coursesEdit);
  const categories = useSelector((state) => state?.categories);
  const [valueDescription, setValueDescription] = useState("");
  const [editorValue, setEditorValue] = React.useState(
    RichTextEditor.createValueFromString('', "markdown")
  );
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const onUploadImage = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const refInput = useRef(null);
  const handleUpdateForm = async (data) => {
    setFileUploadLoader(true)

    if (!images.length && imageLink == null) {
      toast.error("Please provide thumbnail image", {
        id: "toast",
        duration: 4000,
        position: "top-center",
      });
      setFileUploadLoader(false)
      return
    }
    if (error) {
      toast.error("Please remove all date related errors", {
        id: "toast",
        duration: 4000,
        position: "top-center",
      });
      setFileUploadLoader(false)
      return
    }
    var newObj;
    let formData = new FormData();
    formData.append("course_end_date", endTime);
    formData.append("meta_tag", metaTag.join(","));
    formData.append("course_start_date", dateTime);
    formData.append("enrollment_end_date", enrollmentEnd);
    formData.append("start_date_of_course", enrollmentStart);

    formData.append("intro_video", data.intro_video);
    formData.append("enrollment_start_date", enrollmentStart);
    formData.append("title", data.name);
    formData.append("category", data.category);
    formData.append("duration", duration);
    formData.append("description", valueDescription);
    formData.append("points", data.stars);
    formData.append("key_takeaways", valueDescription);
    formData.append("language", language);
    formData.append("pacing_type", data.pacing_type);
    formData.append("handouts", JSON.stringify(handOuts));

    if (images.length) {
      formData.append("course_image", images[0]?.file);
    } else {
      formData.append("course_image", imageLink);
    }

    if (!params.id) {
      formData.append("organization", data.organization);
      formData.append("run", data.run);
      formData.append("number", data.number);
    }
    if (data?.subjects && data.subjects.length > 0) {
      const subjectsBlob = JSON.stringify(data.subjects)
      formData.append('subjects', subjectsBlob);
    }

    // Check and append classes as JSON Blob if the condition is met
    if (data?.classes && data.classes.length > 0) {
      const classesBlob = JSON.stringify(data.classes)
      formData.append('classes', classesBlob);
    }
    if (metaTag.length < 5) {

      toast.error("Please provide 5s meta tags", {
        id: "toast",
        duration: 4000,
        position: "top-center",
      });
      setFileUploadLoader(false)
      return
    }

    if (params.id) {
      newObj = { data: formData, id: params.id };
      await dispatch(courseEditApi(newObj));
      if (!coursesEdit?.loading && !coursesEdit?.error) {
        setFileUploadLoader(false)
        navigate("/courses");
        reset();
      }
    } else {
      await dispatch(courseAddApi(formData));
      if (!coursesAdd?.loading && !coursesAdd?.error) {
        setFileUploadLoader(false)
        navigate("/courses");
        reset();
      }
    }
  };

  useEffect(() => {
    dispatch(categoryApi());
    dispatch(classGlobalApi());
    dispatch(subjectGlobalApi());
    if (params.id) {
      dispatch(courseDetailsApi(params.id));
    }
  }, []);
  useEffect(() => {
    setDuration(coursesDetails.result[0]?.duration);
    setDateTime(coursesDetails.result[0]?.start);
    setEnd(coursesDetails.result[0]?.end);

    setCat(coursesDetails.result[0]?.category);

    setImageLink(coursesDetails.result[0]?.media?.image?.raw);
    setPacing(coursesDetails.result[0]?.pacing + "_paced");
    setEnrollmentStart(coursesDetails.result[0]?.enrollment_start);
    setEnrollmentEnd(coursesDetails.result[0]?.enrollment_end);
    setLanguage(coursesDetails.result[0]?.language)
    if (coursesDetails.result[0]?.meta_tag) {
      setMetaTag(coursesDetails.result[0]?.meta_tag.split(","));
    }
    if (coursesDetails.result[0]?.handouts) {
      setHandouts(coursesDetails.result[0]?.handouts);
    }
    if (coursesDetails.result[0]?.key_takeaways) {
      setValueDescription(coursesDetails.result[0]?.key_takeaways);

      setEditorValue(
        RichTextEditor.createValueFromString(
          coursesDetails.result[0]?.key_takeaways,
          "html"
        )
      );
    }
  }, [coursesDetails]);
  const handleChipDelete = (element) => {
    var local = metaTag;
    setMetaTag(local.filter((item) => item !== element));
  };
  const handleChipAdd = (value) => {
    if (!metaTag.includes(ref?.current?.value.trim())) {
      setMetaTag([...metaTag, meta]);
      setMeta(null);
      ref.current.value = null;
    } else {
      toast.error("This Meta Tag Already Exists", {
        id: "toast",
        duration: 4000,
        position: "top-center",
      });
    }

  };
  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    let defalutValues = {};
    defalutValues["name"] = result[0]?.name;
    defalutValues["run"] = result[0]?.run;
    defalutValues["language"] = result?.language;
    defalutValues["stars"] = result[0]?.points;
    defalutValues["pacing_type"] = result[0]?.pacing;
    defalutValues["organization"] = result[0]?.org;
    defalutValues["number"] = result[0]?.number;
    defalutValues["description"] = result[0]?.key_takeaways;
    defalutValues["duration"] = result[0]?.duration;
    defalutValues["category"] = result[0]?.category;
    defalutValues["intro_video"] = result[0]?.media?.course_video?.uri;

    defalutValues["signatory_organization"] = result[0]?.signatory_organization;
    defalutValues["stitle"] = result[0]?.stitle;
    defalutValues["sname"] = result[0]?.sname;
    defalutValues["subjects"] = result[0]?.subjects?.map(o => o?.id);
    defalutValues["classes"] = result[0]?.classes?.map(o => o?.id);
    reset(defalutValues);
  }, [result]);
  const getSecondsFromHHMMSS = (value) => {
    const [str1, str2, str3] = value.split(":");

    const val1 = Number(str1);
    const val2 = Number(str2);
    const val3 = Number(str3);

    if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
      return val1;
    }

    if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
      return val1 * 60 + val2;
    }

    if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
      return val1 * 60 * 60 + val2 * 60 + val3;
    }

    return 0;
  };
  const toHHMMSS = (secs) => {
    const secNum = parseInt(secs.toString(), 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [hours, minutes, seconds]
      .map((val) => (val < 10 ? `0${val}` : val))
      .filter((val, index) => val !== "00" || index > 0)
      .join(":")
      .replace(/^0/, "");
  };
  const handleDurtion = (e) => {
    //console.log(e.target.value)
    const value1 = e.target.value;
    const seconds = Math.max(0, getSecondsFromHHMMSS(value1));

    const time = toHHMMSS(seconds);
    //console.log(time)
    setDuration(time);
  }
  const handleChange = (value) => {
    setEditorValue(value);
    setValueDescription(value.toString("html"));
  };

  const handleHandoutDelete = (element) => {
    var local = handOuts;
    setHandouts(local.filter((item) => item !== element));
  };

  const onUploadHandout = async (file) => {
    setFileUploadLoader(true)
    let formData = new FormData();
    formData.append("file", file);
    dispatch(uploadHandoutsApi(formData)).then((res) => {
      if (res.payload.result.length > 0 && !res.payload.result.error) {
        let obj = {
          fileName: file.name,
          fileUrl: res.payload.result,
        };
        setHandouts([...handOuts, obj]);
        refInput.current.value = null;
        setFileUploadLoader(false)
      }
    })
  };
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={coursesDetails.loading || coursesEdit?.loading || coursesAdd?.loading || uploadHandouts?.loading
        }

      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Appbar />
      <Drawer />
      <Body>
        <form id="outer_form" onSubmit={handleSubmit(handleUpdateForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Typography variant="h4">Course Details</Typography>
                <Stack spacing={2} my={2}>
                  {/* {accountToggle == "accountToggle" && */}
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Title*</InputLabel>
                        <OutlinedInput
                          {...register("name")}
                          label="Title"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.name?.message}
                          defaultValue={coursesDetails.result[0]?.name}
                        />
                        <FormHelperText error>
                          {errors?.name?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Course Duration*</InputLabel>
                        <OutlinedInput
                          // views={["minutes", "seconds"]}
                          {...register("duration")}
                          label="Course Duration"
                          name="duration"
                          id="outlined-adornment-email"
                          notched
                          value={duration}
                          onChange={(e) => handleDurtion(e)}
                          error={errors?.duration?.message}
                          defaultValue={
                            coursesDetails.result[0]?.duration
                          }
                        />
                        <FormHelperText error>
                          {errors?.duration?.message}
                        </FormHelperText>

                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Allocated Stars*</InputLabel>
                        <OutlinedInput
                          {...register("stars")}
                          label="Allocated Stars"
                          id="outlined-adornment-email"
                          type="number"
                          error={errors?.stars?.message}
                          notched
                          defaultValue={coursesDetails.result[0]?.points}
                        />
                        <FormHelperText error>
                          {errors?.stars?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Category*</InputLabel>
                        <Select
                          {...register("category")}
                          label="Category"
                          id="outlined-adornment-category"
                          value={cat ? cat : null}
                          notched
                          error={errors?.stars?.message}
                          defaultValue={coursesDetails.result[0]?.category}
                          onChange={(e) => setCat(e.target.value)}
                        >
                          {categories?.result?.slice().sort((a, b) => {
                            if (a.title < b.title) {
                              return -1;
                            }
                            if (a.title > b.title) {
                              return 1;
                            }
                            return 0;
                          })?.map(({ id, title }, index) => (
                            <MenuItem value={title} key={index}>
                              {title}
                            </MenuItem>
                          ))}
                        </Select>

                        <FormHelperText error>
                          {errors?.category?.message}
                        </FormHelperText>
                      </FormControl>

                      <FormControl variant="outlined" fullWidth>
                        <InputLabel>Meta Tag*</InputLabel>
                        <OutlinedInput
                          label="meta_tag"
                          id="outlined-adornment-email"
                          onChange={(e) => {
                            if (e.target.value.trim().length > 0) {
                              setMeta(e.target.value);
                            } else {
                              setMeta(null);
                              ref.current.value = null;
                            }
                          }}
                          defaultValue={meta}
                          inputRef={ref}
                          endAdornment={
                            <Button
                              variant="contained"
                              className="btn3"
                              disabled={
                                ref?.current?.value.trim().length > 0
                                  ? false
                                  : true
                              }
                              onClick={() => handleChipAdd()}
                            >
                              Add
                            </Button>
                          }
                        />
                        <FormHelperText error></FormHelperText>

                        <Stack
                          direction="row"
                          spacing={1}
                          style={{ display: " inline" }}
                        >
                          {metaTag.map((i, element) => (
                            <>
                              <Chip
                                label={i}
                                variant="outlined"
                                className=""
                                style={{ marginTop: "5px" }}
                                onDelete={() => handleChipDelete(i)}
                              />
                            </>
                          ))}
                        </Stack>
                      </FormControl>
                    </Stack>
                  </>
                  {/* } */}
                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          {...register("startTime")}
                          label="Start*"
                          name="startTime"
                          id="outlined-adornment-email"
                          onError={(newError) => setError(newError)}
                          maxDateTime={endTime ? dayjs(endTime) : null}
                          // maxTime={endTime ? dayjs(endTime) : null}

                          placeholder="Start"
                          error={errors?.startTime?.message}
                          value={dateTime ? dayjs(dateTime) : null}
                          onChange={(newValue) => {
                            setDateTime(dayjs(newValue).toJSON());
                          }}
                        />
                      </LocalizationProvider>
                      <FormHelperText error>
                        {errors?.startTime?.message}
                      </FormHelperText>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel shrink>Pacing type*</InputLabel>
                      <Select
                        {...register("pacing_type")}
                        label="Pacing type"
                        id="outlined-adornment-category"
                        value={pacing ? pacing : null}
                        error={errors?.pacing_type?.message}
                        notched
                        // defaultValue={}
                        onChange={(e) => setPacing(e.target.value)}
                      >
                        <MenuItem value={"instructor_paced"}>
                          Instructor Paced
                        </MenuItem>
                        <MenuItem value={"self_paced"}>Self Paced</MenuItem>
                        {/* <MenuItem value={"30"}>Thirty</MenuItem> */}
                      </Select>

                      <FormHelperText error>
                        {errors?.pacing_type?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>


                  <Stack direction="row" spacing={2}>
                    {params.id ? null : (
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Organization*</InputLabel>
                        <OutlinedInput
                          {...register("organization")}
                          label="organization"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.organization?.message}
                          onKeyDown={(event) => {
                            if (event.key === ' ') {
                              event.preventDefault();
                            }
                          }}
                        />
                        <FormHelperText error>
                          {errors?.organization?.message}
                        </FormHelperText>
                      </FormControl>
                    )}
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-email" shrink>
                        Language
                      </InputLabel>
                      <Select
                        {...register("language")}
                        label="Language"
                        notched
                        id="outlined-adornment-category"
                        error={!!errors?.language?.message}
                        value={language ? language : null}
                        onChange={(e) => setLanguage(e.target.value)}
                      >
                        <MenuItem value={"hindi"}>Hindi</MenuItem>
                        <MenuItem value={"english"}>English</MenuItem>
                        <MenuItem value={"both"}>Both</MenuItem>
                      </Select>

                      <FormHelperText error>
                        {errors?.language?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-email" shrink>
                        Subject
                      </InputLabel>
                      <Controller
                        name="subjects"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                          <Select
                            {...field}
                            multiple
                            value={watch('subjects', [])}
                            onChange={(e) => {
                              const {
                                target: { value },
                              } = e;
                              setValue('subjects', typeof value === 'string' ? value.split(',') : value);
                              field.onChange(e);
                            }}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={subjectList.find(obj => obj.id === value)?.title || ""} />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {subjectList.map((obj) => (
                              <MenuItem
                                key={obj.title}
                                value={obj.id}
                                style={getStyles(obj.title, watch('subjects', []), theme)}
                              >
                                {obj.title}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.subjects && <span>{errors.subjects.message}</span>}
                    </FormControl>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-email" shrink>
                        Class
                      </InputLabel>
                      <Controller
                        name="classes"
                        control={control}
                        defaultValue={[]}
                        render={({ field }) => (
                          <Select
                            {...field}
                            multiple
                            value={watch('classes', [])}
                            onChange={(e) => {
                              const {
                                target: { value },
                              } = e;
                              setValue('classes', typeof value === 'string' ? value.split(',') : value);
                              field.onChange(e);
                            }}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                  <Chip key={value} label={classList.find(obj => obj.id === value)?.title || ""} />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {classList.map((obj) => (
                              <MenuItem
                                key={obj.title}
                                value={obj.id}
                                style={getStyles(obj.title, watch('classes', []), theme)}
                              >
                                {obj.title}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      {errors.classes && <span>{errors.classes.message}</span>}
                      <FormHelperText error>
                        {errors?.classes?.message}
                      </FormHelperText>
                    </FormControl>
                  </Stack>


                  {params.id ? null : (
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Course Number*</InputLabel>
                        <OutlinedInput
                          {...register("number")}
                          label="Course Number"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.number?.message}
                        // defaultValue={number}
                        />
                        <FormHelperText error>
                          {errors?.number?.message}
                        </FormHelperText>
                      </FormControl>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel shrink>Run*</InputLabel>
                        <OutlinedInput
                          {...register("run")}
                          label="Run"
                          notched
                          id="outlined-adornment-email"
                          error={errors?.run?.message}
                        // defaultValue={run}
                        />
                        <FormHelperText error>
                          {errors?.run?.message}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                  )}


                  <Stack direction="row" spacing={2}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel shrink>Video Link*</InputLabel>
                      <OutlinedInput
                        {...register("intro_video")}
                        label="Video Link"
                        notched
                        id="outlined-adornment-email"
                        error={errors?.intro_video?.message}
                        defaultValue={result[0]?.media?.course_video?.uri}
                      />
                      <FormHelperText error>
                        {errors?.intro_video?.message}
                      </FormHelperText>
                    </FormControl>

                  </Stack>

                  <InputLabel htmlFor="outlined-adornment-email" shrink>
                    Description
                  </InputLabel>
                  <RichTextEditor
                    value={editorValue}
                    onChange={handleChange}
                    required
                    id="body-text"
                    name="bodyText"
                    type="string"
                    multiline
                    variant="filled"
                    style={{ minHeight: 410, marginBottom: '10%' }}
                  />
                </Stack>
                <InputLabel>Thumbnail Image</InputLabel>
                {imageLink ? (
                  <div className="image-item">
                    <img src={imageLink} alt="" width="320" height="180" />
                    <div className="image-item__btn-wrapper">
                      <Button
                        onClick={() => setImageLink(null)}
                        variant="contained"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                ) : (
                  <ImageUploading
                    // multiple
                    acceptType={['png', 'jpeg', 'jpg']}
                    value={images}
                    onChange={onUploadImage}
                    maxNumber={1}
                    // resolutionType="ratio"
                    dataURLKey="data_url"
                  // resolutionWidth={16}
                  // resolutionHeight={9}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      <>
                        <div
                          className="upload__image-wrapper"
                          style={{ marginTop: "2%", marginBottom: "2%" }}
                        >
                          {imageList.length > 0 ? null : (
                            <Button
                              type="button"
                              variant="contained"
                              style={isDragging ? { color: "red" } : undefined}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              Click or Drop here
                            </Button>
                          )}

                          {errors && (
                            <div>
                              {errors.maxNumber && (
                                <span>
                                  Number of selected images exceed maxNumber
                                </span>
                              )}
                              {errors.acceptType && (
                                <span>
                                  Your selected file type is not allow
                                </span>
                              )}
                              {errors.maxFileSize && (
                                <span>
                                  Selected file size exceed maxFileSize
                                </span>
                              )}
                              {errors.resolution && (
                                <span>
                                  Selected file is not match your desired
                                  resolution i.e (328*150)
                                </span>
                              )}
                            </div>
                          )}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img
                                src={image["data_url"]}
                                alt=""
                                width="320" height="180"
                              />
                              <div className="image-item__btn-wrapper">
                                <Button
                                  onClick={() => onImageUpdate(index)}
                                  variant="contained"
                                  sx={{ mr: 2 }}
                                >
                                  Update
                                </Button>
                                <Button
                                  onClick={() => onImageRemove(index)}
                                  variant="contained"
                                >
                                  Remove
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </ImageUploading>
                )}
                <InputLabel style={{ marginTop: "2%", marginBottom: "2%" }}>
                  Handout Documents
                </InputLabel>
                <Stack
                  direction="row"
                  spacing={1}
                  style={{ display: " inline" }}
                >
                  {handOuts?.map((i, element) => (
                    <>
                      <Chip
                        key={element}
                        label={i.fileName}
                        variant="outlined"
                        className=""
                        style={{ marginTop: "5px" }}
                        onDelete={() => handleHandoutDelete(i)}
                      />
                    </>
                  ))}
                </Stack>
                <input
                  accept="application/pdf,.doc,.docx"
                  style={{ display: "none" }}
                  id="raised-button-file"
                  type="file"
                  ref={refInput}
                  onChange={(e) => onUploadHandout(e.target?.files[0])}
                />
                <Stack
                  direction="row"
                  spacing={1}
                  style={{ display: " block", marginTop: "2%" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => refInput.current.click()}
                  >
                    Upload File
                  </Button>
                </Stack>


                {params?.id && <AddCouresSection />}
              </Card>
              <Button
                sx={{ float: "right", mt: 2, ml: 2 }}
                variant="contained"
                onClick={() => navigate("/courses")}
              >
                Cancel
              </Button>
              <Button
                disabled={coursesEdit?.loading || courseAddApi?.loading}
                type="submit"
                sx={{ float: "right", mt: 2 }}
                variant="contained"
              >
                {params.id ? "Update" : "Create"}
              </Button>

            </Grid>
          </Grid>
        </form>
      </Body>
      <BackdropUI open={fileUploadLoader} />
    </>
  );
}
